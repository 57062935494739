import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
    <Layout>
        <SEO title="Privacy Policy"/>
        <div className="cols-2-1" id="privacy-policy">
            <div>
                <h3 class="h3-on-h1" style={{marginTop: `1em`}}>Privacy Policy</h3>
                <h1>I, Nadzim Al-Rash, take your personal privacy very seriously.</h1>
                <p>As a general rule, I do not collect your personal information unless you chose to provide that information to me. When you choose to provide me with your personal information, you are giving me your permission to use that information for the stated purposes listed in this privacy policy. If you choose not to provide me with that information, it might limit the features and services that you can use on this website.</p>

                <p>Generally, the information requested by this website will be used to provide a website feature or service to you, such as commenting, support, or providing future content better tailored to your interests. A description of my intended use of that information, how that information is collected, security measures I take to protect that information, and how to grant or revoke consent for collection and use of that information will be fully described in this Privacy Policy.</p>

                <h3>Contact Form</h3>

                <p>The Contact Form on this website allows you to send me an email. I will use the information you provide, such as email address or phone number, only to respond to your inquiry. Keep in mind that email transmissions are not encrypted by default, so I suggest you do not send sensitive information such as credit card numbers or bank account information via such contact forms.</p>

                <h3>Third-party Applications</h3>

                <p>This website uses commenting and social media plug-ins and third-party websites. I use those third-party services to interact with visitors and to build our community on social media. I also use these third-party services to measure the number of visitors to our website, to interact with visitors on the site, and to make this website more useful to visitors.</p>

                <p>In such cases, the third-party application may request an email address, username, password, internet protocol (IP) address, and geographic location for account registration or sign-in purposes. (website) does not use those third-party websites or services to collect personal information from individuals. Any personal information collected by the third-party website will not be stored or transmitted by me. I have no control over or access to specific login information or any other sensitive personal information provide to third-party websites.</p>

                <h3>Cookies and Analytics</h3>

                <p>This website uses cookies to provide a customised user experience. A cookie is a small file that a website transfers to your computer to allow your browser to remember information about your last session on that website. Your computer only shares information in the cookie with the specific website that provided it, and no other website can request that information. This website also use third-party analytics services (like Google Analytics) to gather this visitor behaviour information for analysis.</p>

                <p>This website collects and temporarily stores certain information about your visit to help me to better align my content and the website design with your needs. The information these cookies collect includes:</p>
                <ol>
                    <li>The domain you access our website from</li>
                    <li>Your computer’s IP address</li>
                    <li>The date and time you accessed the site</li>
                    <li>The operating system of your computer</li>
                    <li>The browser you’re using to access our site</li>
                    <li>The Universal Resource Locators (URLs) of the pages you visit on our website</li>
                    <li>Your username, if you’ve logged into the site</li>
                    <li>The URL of site you came from, if you clicked a link there that brought you to our website</li>
                </ol>

                <p>I will not share this information with any third-party contractors. This information is only used to improve the website and enhance visitors' experience. Raw data logs are only retained temporarily site management purposes.</p>

                <p>If you do not wish to have cookies stored on your machine, you have the option to turn cookies off in your browser. However, keep in mind that turning off cookies may impact how this website functions. Disabling browser cookies will also impact how other websites you visit store browser cookies as well.</p>

                <h3>Security of Your Information</h3>

                <p>I treat your personal information security very seriously. I take many precautions to ensure that the information I collect is secure and inaccessible by anyone outside of this organisation. These precautions include advanced access controls to limit access to that information to only internal personnel who require access to that information. I also use numerous security technologies to protect all data stored on my servers and related systems. My security measures are regularly upgraded and tested to ensure they are effective.</p>

                <p>I take the following specific steps to protect your information:</p>
                <ol>
                    <li>Use internal access controls so only limited personnel have access to your information.</li>
                    <li>Servers that store visitor information are regularly backed up to protect against loss.</li>
                    <li>All information is secured through modern security technologies like secure socket layer (SSL), encryption, firewalls, and secure passwords.</li>
                </ol>

                <p>All access safeguards described above are in place to prevent unauthorised access by outsiders to information stored on or transmitted by our systems.</p>

                <h3>I Respect Your Rights</h3>

                <p>You can do the following at any time by contacting me via the email address or phone number given on our website:</p>

                <ol>
                    <li>Ask for a list of personal information I have about you, if any.</li>
                    <li>Request a change, correction, or deletion of your personal information.</li>
                    <li>Request that I avoid collecting anything in the future (opt-out).</li>
                </ol>

                <p>If you believe at any point that I am not following this privacy policy as stated, please contact me immediately via email at <a href="mailto:hello@nadzim.com">hello@nadzim.com</a>.</p>
            </div>
        </div>
    </Layout>
)

export default PrivacyPage